<template lang="pug">
div.month-choiser
  .costs-list-subtitle.m-b-30
      strong {{ customStyle.period_title }}:
  .contract-choicer-container
    div(v-for="(fixedCost, index) in sortedPeriods" :key="index")
      input.radio-block-input(
        type="radio"
        data-vv-name="month"
        v-validate="'required'"
        :checked="month === fixedCost.months"
        :id="`month${index}`"
        :value="fixedCost.months"
        @change.stop="setVehicleCostId(fixedCost)"
      )
      label.radio-block(:for="`month${index}`")
        .radio-block-title {{fixedCost.months}} {{ $t('months') }}
        div(v-if="showPices")
          .radio-block-info(v-if="index === 0") {{ $t("steps.costs.standard") }}
          .radio-block-info(v-else)
            span + {{fixedCost.general_price - fixedCostsBy[0].general_price}}
            | {{ currencySymbol }}/{{ $t('mon') }} <br>

    small.text-danger {{ errors.first('getDefaults[getPriceForm][month]:required') }}
</template>

<script>
import {mapState, mapGetters, mapActions,} from 'vuex'

export default {
  props: {
    fixedCostsBy: Array,
    showPices: Boolean,
  },
  computed: {
    ...mapState('product', [ 'vehicle', 'priceForm' ]),
    ...mapState('order', ['residual', 'cashPayment']),
    ...mapState('reseller', {
      customStyle: state => state.customStyle.firstStep || {},
      currencySymbol: state => state.resellerInfo?.market?.currency?.symbol || 'kr',
    }),
    ...mapGetters('product', [ 'getDefaults', 'getPriceForm', ]),
    ...mapGetters('order', [ 'vehicleCostId', ]),
    sortedPeriods() {
      return [...this.fixedCostsBy].sort((a, b) => a.months - b.months)
    },
    vehicleCostId: {
      get() {
        return this.$store.state.order.vehicleCostId
      },
      set(value) {
        this.$store.commit('order/setVehicleCostId', value)
      }
    },
    month() {
      return this.getDefaults[this.getPriceForm]['months'];
    },
    distance() {
      return this.getDefaults[this.getPriceForm]['distance'];
    }
  },
  methods: {
    ...mapActions('product', ['getFixedCostByMonthAndDistance', 'updateDefaults', ]),
    async setVehicleCostId(fixedCost) {
      const {id, months, residual,} = fixedCost;
      this.vehicleCostId = id;

      const cost = await this.getFixedCostByMonthAndDistance([months, this.getDefaults[this.getPriceForm]['distance']])
      await this.$store.dispatch('order/saveAllFields', {
        'vehicleCostId': cost.id,
        'totalMonthlyPrice': cost.general_price,
        'residual': residual,
      })

      await this.$store.dispatch('product/updateVehicle', {
        'vehicleCostId': cost.id,
        'residual': residual,
      });

      this.updateDefaults({
        form: this.getPriceForm,
        months: months,
        id: cost.id,
        residual: residual,
      })
    },
  },
}
</script>